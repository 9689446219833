.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.header {
  margin-bottom: 30px;
  font-weight: bold;
}

.header_line {
  margin-bottom: 10px;
}

.arabic_style {
  font-size: 150%;
  font-weight: 600;
}

p.arabic_style {
  font-weight: 400;
}

.popup form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup form label{
  margin-bottom: 10px;
}

.container p {
  margin-bottom: 10px;
}

.popup .checkbox {
  display: block;
  width: 18px;
  height: 18px;
  padding: 8px 12px;
  margin-right: 15px;
  
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #D1D1D1;
}

.popup label {
  display: flex;
}

.popup .box_text {
  display: flex;
  flex-wrap: wrap;
}

.popup .divider {
  margin-left: 5px;
  margin-right: 5px;
}

.popup_term {
  height: 250px;
  margin-bottom: 20px;
}

.err_msg {
  color: red;
  font-size: 80%;
}

.popup_term_text {
  overflow-y: scroll;
  height: 100%;
}

.popup .popup_btn {
  background-color: transparent;
  font-size: 16px;
  text-transform: none;
  color: gray;
  cursor: pointer;
  border: 2px solid #dcdce0;
}

.popup_logo {
  max-width: 200px;
  margin: 0 auto;
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .title {
  margin-bottom: 20px;
  text-align: center;
}
.popup .close {
  border: none;
  background: transparent;
  position: absolute;
  right: 24px;
  top: 28px;
  width: 16px;
  height: 16px;
}
.popup .close:hover {
  cursor: pointer;
}
.popup .close:before, .popup .close:after {
  content: ' ';
  position: absolute;
  left: 8px;
  top: 8px;
  height: 16px;
  width: 1px;
  background-color: #333;
}
.popup .close:before {
  transform: rotate(45deg);
}
.popup .close:after {
  transform: rotate(-45deg);
}
.popup .popup_inner {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 36px 38px;
  padding-top: 0;
  max-width: 675px;
  margin: auto;
  background: white;
  left: 10%;
  right: 10%;
  top: 10%;
  box-shadow: 2px 3px 5px #999;
}
@media (max-width: 968px) {
  .popup .popup_inner {
    left: 10%;
    right: 10%;
 }
}
@media (max-width: 768px) {
  .popup .popup_inner {
    left: 30px;
    right: 30px;
 }
}
@media (max-width: 568px) {
  .popup .popup_inner {
    left: 0px;
    right: 0px;
    padding: 24px 16px;
 }
}
.popup .popup_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
  margin-bottom: 25px;
}
.popup .popup_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: auto;
}
@media (max-width: 568px) {
  .popup .popup_footer {
    justify-content: center;
 }
}
.popup .popup_btn {
  max-width: 100%;
  width: auto;
  padding: 11px 50px;
}
@media (max-width: 568px) {
  .popup .popup_btn {
    max-width: 50%;
    width: 100%;
    padding: 11px 15px;
 }
}
@media (max-width: 390px) {
  .popup .popup_btn {
    max-width: 100%;
 }
}
@media (max-width: 568px) {
  .popup .popup_btn.popup_btn_noborder {
    background-color: transparent;
 }
}
.popup.popup_frame_style .popup_footer {
  justify-content: flex-start;
  position: relative;
}
.popup.popup_frame_style .popup_footer .popup_btn:nth-child(1) {
  position: absolute;
  top: -40px;
}
@media (max-width: 568px) {
  .popup.popup_frame_style .popup_footer .popup_btn:nth-child(1) {
    top: -135px;
 }
}
@media (max-width: 630px) {
  .popup.popup_frame_style .popup_footer .popup_btn:nth-child(1) {
    top: -75px;
 }
}
.popup.popup_frame_style .popup_content {
  margin-bottom: 0;
}

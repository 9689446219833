.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.container button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
  cursor: pointer;
  display: block;
  margin-left: auto;
}

.container button:hover {
  color: #3898EC;
}

.button_divider {
  margin-left: 5px;
  margin-right: 5px;
}

.header {
  margin-bottom: 30px;
  font-weight: bold;
}

.header_line {
  margin-bottom: 10px;
}

.arabic_style {
  font-size: 150%;
  font-weight: 600;
}

.err_msg {
  color: red;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form_wrap form label{
  margin-bottom: 30px;
  font-weight: bold;
}

.form_wrap form label .arabic {
  margin-top: 10px;
}

.form_wrap form input {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.form_wrap input:focus {
  border-color: #3898EC;
  outline: 0;
}

.form_wrap input::placeholder {
  color: #333333;
  font-style: italic;
  opacity: 0.7;
}

.form_wrap input::-webkit-outer-spin-button,
.form_wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form_wrap input[type=number] {
  -moz-appearance: textfield;
}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.container button {
  background-color: transparent;
  border: none;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
  cursor: pointer;
}

.container button:hover {
  color: #3898EC;
}

.header {
  margin-bottom: 30px;
  font-weight: bold;
}

.header_line {
  margin-bottom: 10px;
}

.arabic_style {
  font-size: 150%;
  font-weight: 600;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.checkbox_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.checkbox_line {
  margin-bottom: 15px;
  padding-right: 10px;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.checkbox_line label {
  display: flex;
}

.checkbox_line .checkbox_wrap {
  height: 100%;
}

.checkbox_line .symptom_text {
  display: flex;
  flex-wrap: wrap;
}

.form_wrap form .checkbox {
  display: block;
  width: 18px;
  height: 18px;
  padding: 8px 12px;
  margin-right: 15px;
  
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #D1D1D1;
}

.submit {
  margin-left: auto;
}

.button_divider {
  margin-left: 5px;
  margin-right: 5px;
}


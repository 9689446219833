.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.header {
  margin-bottom: 30px;
  font-weight: bold;
}

.header_line {
  margin-bottom: 10px;
}

.arabic_style {
  font-size: 150%;
  font-weight: 600;
}

p.arabic_style {
  font-weight: 400;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.form_wrap form label{
  margin-bottom: 15px;
}

.container p {
  margin-bottom: 10px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
}

.btn_wrap {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.submit_btn_wrap { 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer_wrap {
  font-size: 25px;
  display: flex;
  justify-content: center;
}

.container button, .submit {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
  cursor: pointer;
}

.container .submit {
  position: relative;
  padding: 5px 20px;
  font-size: 22px;
  text-transform: none;
  border: 2px solid #dcdce0;
}

.container button:hover {
  color: #3898EC;
}

.button_divider {
  margin-left: 7px;
  margin-right: 7px;
}

.container .submit.button__loading {
  padding-right: 50px;
}

.button__loading::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #405aa1b0;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.header {
  margin-bottom: 30px;
}

.header_line {
  margin-bottom: 10px;
}

.arabic_style {
  font-size: 150%;
  font-weight: 600;
}

.arabic_text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.form_wrap form label{
  margin-bottom: 15px;
}

.list {
  line-height: 1.3;
  padding-left: 17px;
}

.list li{
  margin-bottom: 5px;
}


.start_btn {
  max-width: 180px;
}